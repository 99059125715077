* {
  /* box-sizing: border-box; */
  margin: 0;
  padding: 0;
}

.wait {
  cursor: wait;
}

.App {
  padding-bottom: 100px;
  position: relative;
  height: 100% !important;
}

li, a, button {
  color: #edf0f1;
  text-decoration: none;
}

body {
  background-color: #111;
  color: #edf0f1;
  height: 100%;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.gif {
  border-radius: 15px;
}

.no-margin {
  margin: 0;
}

main {
  /* margin: 3rem 15vw; */
  padding-bottom: 100px;
  min-height: 57.5vh;
}

main > p {
  margin: 2rem 0;
  text-align: justify;
  align-items: center;
  line-height: 1.5;
  background-color: rgba(255,255,255,0.6);
  color: #111;
  padding: 10px;
  border-radius: 15px;
}

main > p > p {
  padding: 2rem;
}

main p ul, main p ul li {
  margin-left: 1vw;
  color: #111;
}

header {
  background-color: #fff;
  display: flex;
  /* position: fixed;
  width: 100%; */
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
  margin-top: 0;
}

.logo {
  margin: 0;
  height: 40px;
  width: 120px;
  cursor: pointer;
  font-family: sans-serif;
}

nav li, .nav-header li {
  color: #226699;
  font-weight: bold;
  list-style: none;
  display: inline-block;
  padding: 0 20px;
  cursor: pointer;
}

.nav-header ul li {
  display: block;
  margin: 15px 0;
}

.nav_btn button, div button {
  cursor: pointer;
  background-color: #226699;
  font-weight: bold;
  font-size: 11pt;
  border: none;
  border-radius: 25px;
  padding: 5px 14px;
}

.nav_btn button:hover {
  background-color: #3f87bf;
}

.hover-update:hover {
  background-color: #3f87bf !important;
}

.hover-red:hover {
  background-color: #E55451 !important;
}

.navlink {
  color: #226699;
}

.navlink:hover {
  color: #3f87bf;
}

.navlink.active {
  text-decoration: underline;
  text-decoration-thickness: 16%;
}

.footer {
  background-color: black;
  bottom: 0;
  position: absolute;
  width: 100%;
  font-size: 10pt;
  border-top: solid 1px #fff;
  padding: 2vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
}

.footer div {
  display: inline-block;
}

.contact-icon {
  height: 50;
  width: 50;
  margin: 0 5px;
}

input, .input {
  border: 1px solid #edf0f1;
  border-radius: 10px;
  margin: 1rem;
  min-width: 300px;
  text-align: center;
  font-size: 12px;
  color: #edf0f1;
  background-color: #434343;
  padding: 5px;
  height: 30px;
}

section {
  margin: 30px 0;
}

.right-click-menu {
  position: fixed;
  background-color: #fff;
  margin-top: 5px;
  border-radius: 6px;
  box-shadow: 0 10px 35px rgba(111,111,111,0.85);
  overflow: hidden;
}

.right-click-menu-item {
  color: #111;
  cursor: pointer;
  padding: 5px;
}

.right-click-menu-item:hover {
  color: #222;
  background-color: #eee;
}

/* .fancy-border {
  --r:15px;  
  --g:#111,#111, rgba(255,255,255,0.5), rgba(255,255,255,0.1);
  
  border-radius:var(--r);
  padding:calc(var(--r) + 5px);
  background:
    radial-gradient(farthest-side at bottom right,var(--g)) top    left /var(--r) var(--r),
    radial-gradient(farthest-side at top    right,var(--g)) bottom left /var(--r) var(--r),
    radial-gradient(farthest-side at bottom left ,var(--g)) top    right/var(--r) var(--r),
    radial-gradient(farthest-side at top    left ,var(--g)) bottom right/var(--r) var(--r),
    linear-gradient(to top   ,var(--g)) top   /calc(100% - 2*var(--r)) var(--r),
    linear-gradient(to bottom,var(--g)) bottom/calc(100% - 2*var(--r)) var(--r),
    linear-gradient(to right ,var(--g)) right /var(--r) calc(100% - 2*var(--r)),
    linear-gradient(to left  ,var(--g)) left  /var(--r) calc(100% - 2*var(--r));
    background-repeat:no-repeat;
    display:inline-block;
    display:inline-block;
    vertical-align:top;
} */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #111;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #eee;
}

@media screen and (min-width: 700px) {
  .box2 {
    display: block;
  }
  .half {
    max-width: 100%;
  }
}

@media screen and (min-width: 1000px){
  .box2 {
    display: flex;
    justify-content: space-between;
  }
  
  .half {
    flex:1;
    max-width: 49%;
    display: inline-flex;
  }
}

.btn-disabled {
  background-color: #888;
  color: #111;
  cursor: not-allowed;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 350px;
}