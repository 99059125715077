/* table {
    border-spacing: 2px;
	mso-border-spacing-alt: 0;
    color: rgba(111,111,111,0.8);
}

th, .header td {
    background-color: rgb(32,56,100);
    color: white;
    padding: 3px 5px;
	text-align: center;
	border-bottom: 3px solid black;
	mso-border-bottom-alt: 3px solid black;
} */

.header {
    background-color: rgb(32,56,100);
    color: white;
    padding: 3px 5px;
	text-align: center;
	border-bottom: 3px solid black;
	mso-border-bottom-alt: 3px solid black;
}

.buyback {
    background-color: #e2f0d9;
}

.issuance {
    background-color: #deebf7;
}

.sector {
    border: 2px solid black;
	mso-border-alt: 2px solid black;
}

.bold {
    font-weight: bold;
    color: #000;
}

.sep-right {
	border-right:3px solid black;
	mso-border-right-alt:3px solid black;
}

/* td {
    border-right: 1px solid rgba(111,111,111,0.8);
    border-left: 1px solid rgba(111,111,111,0.8);
	mso-border-right-alt: 1px solid rgba(111,111,111,0.8);
    mso-border-left-alt: 1px solid rgba(111,111,111,0.8);
    padding: 5px 9px;
} */

.num {
    text-align: right;
}